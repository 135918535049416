import React, { useContext, useState } from 'react';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import css from './App.scss';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ComponentLoader from './Pages/ComponentLoader';
import { useQuery, ApolloProvider } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { AppContext, ACCESS_TOKEN_KEY } from './utils/AppContext';
import apolloClient from './utils/apolloClient';
import { GET_USER_BY_TOKEN } from './Pages/Login/queries';
import NotificationBell from 'partials/NotificationBell';
import Router from './router';
import * as jwt from 'jsonwebtoken';
import GlobalLoader from 'partials/GlobalLoader';
import { config } from 'utils/config';

const ALL_ROLES = ['accountant', 'operator', 'sales', 'data-entry', 'supervisor', 'admin'];

const WORKFLOWS_QUERY = gql`
  query workflows {
    workflows {
      id
      type
      definition
    }
  }
`;

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

function AppMenu(props: any) {
  const { sectionKey, linkKey, navigation, createTab } = props;
  return (
    <AppContext.Consumer>
      {({ language, changeLanguage, setAccessToken, getRole, getUser }: any) => (
        <Menu
          selectedKeys={[`${sectionKey}:${linkKey}`]}
          mode='horizontal'
          theme='dark'
          style={config.ENV === 'master' ? undefined : { background: '#1890ff' }}
        >
          {Object.keys(navigation)
            .filter(
              (nodeKey: string) =>
                !navigation[nodeKey].roles || navigation[nodeKey].roles.includes(getRole()),
            )
            .map((nodeKey: any) => {
              const node = navigation[nodeKey];

              const urlKey = node.filterQueryKey || nodeKey;

              if (!node.menu) {
                return (
                  <Menu.Item key={`${nodeKey}`}>
                    <Link to={`/${nodeKey}`} onClick={() => createTab(`/${nodeKey}`, `${node.name}`)}>
                      {node.name}
                    </Link>
                  </Menu.Item>
                );
              }

              return (
                <SubMenu
                  key={nodeKey}
                  title={
                    <span className={css['submenu-title-wrapper']}>
                      <Icon type='appstore' />
                      {node.name}
                    </span>
                  }
                >
                  {node.menu &&
                    Object.keys(node.menu)
                      .filter(
                        (childKey: string) =>
                          !Array.isArray(node.menu[childKey].roles) ||
                          node.menu[childKey].roles.includes(getRole()),
                      )
                      .map((childKey: string) => {
                        const child = node.menu[childKey];
                        const conditions = child['conditions'];

                        return (
                          <Menu.Item key={`${nodeKey}:${childKey}`}>
                            <Link
                              to={`/${nodeKey}/${childKey}${(conditions &&
                                `?${urlKey}_conditions=${JSON.stringify(conditions)}`) ||
                                ''}`}
                              onClick={() =>
                                createTab(`/${nodeKey}/${childKey}`, `${node.name} — ${child.name}`)
                              }
                            >
                              {child.name}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                </SubMenu>
              );
            })}

          <SubMenu
            title={
              <span className={css['submenu-title-wrapper']}>
                <Icon type='user' />
                {getUser().name}
              </span>
            }
            style={{ float: 'right' }}
          >
            <Menu.Item onClick={() => changeLanguage('en')}>English</Menu.Item>
            {/* <Menu.Item onClick={() => changeLanguage('ar')}>العربية</Menu.Item> */}
            <Menu.Divider />
            <Menu.Item onClick={() => setAccessToken(null)}>
              <Icon type='logout' />
              Logout
            </Menu.Item>
          </SubMenu>
          <SubMenu title={<NotificationBell />} style={{ float: 'right', width: 45 }} />
        </Menu>
      )}
    </AppContext.Consumer>
  );
}

function buildNavigations(workflows: any[], userId: string): any {
  const navigation = {
    '': {
      name: 'Dashboard',
      roles: ALL_ROLES,
    },
    checkilsts: {
      name: 'Checklists',
      roles: ALL_ROLES,
      menu: {
        checklists: {
          name: 'Checklists',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "checklists" */ './Pages/Lists/Checklists'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "checklists-form" */ './Pages/Forms/Checklists'),
          ),
        },
        checkliststeps: {
          name: 'Checklist steps',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "checklist-steps" */ './Pages/Lists/ChecklistSteps'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "checklist-steps-form" */ './Pages/Forms/ChecklistSteps'),
          ),
        },
      },
    },
    tripCheckilsts: {
      name: 'Trip checklists',
      roles: ALL_ROLES,
      menu: {
        tripChecklists: {
          name: 'Trip checklists',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "trip-checklists" */ './Pages/Lists/TripChecklists'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "trip-checklists-form" */ './Pages/Forms/TripChecklists'),
          ),
          conditions: { userId: null },
        },
      },
    },
    firmwares: {
      name: 'Firmwares',
      roles: ALL_ROLES,
      menu: {
        firmwares: {
          name: 'Firmwares',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "firmwares" */ './Pages/Lists/Firmwares')),
          form: React.lazy(() => import(/* webpackChunkName: "firmwares-form" */ './Pages/Forms/Firmwares')),
        },
      },
    },
    downloadscenter: {
      name: 'Downloads center',
      roles: ALL_ROLES,
      menu: {
        downloadscenter: {
          name: 'Downloads center',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "downloadscenter" */ './Pages/Lists/DownloadsCenter'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "downloadscenter-form" */ './Pages/Forms/DownloadsCenter'),
          ),
        },
      },
    },
    configurator: {
      name: 'Configurator',
      roles: ALL_ROLES,
      menu: {
        nodes: {
          name: 'Nodes',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "nodes" */ './Pages/Lists/Nodes')),
          form: React.lazy(() => import(/* webpackChunkName: "nodes-form" */ './Pages/Forms/Nodes')),
        },
        accessoryCategories: {
          name: 'Accessory categories',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "accessorycategories" */ './Pages/Lists/AccessoryCategories'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "accessorycategories-form" */ './Pages/Forms/AccessoryCategories'),
          ),
        },
        accessories: {
          name: 'Accessories',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "accessories" */ './Pages/Lists/Accessories'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "accessories-form" */ './Pages/Forms/Accessories'),
          ),
        },
        accessoryLists: {
          name: 'Accessory lists',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "accessorylists" */ './Pages/Lists/AccessoryLists'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "accessorylists-form" */ './Pages/Forms/AccessoryLists'),
          ),
        },
        accessoryQuestions: {
          name: 'Accessory questions',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "accessoryquestions" */ './Pages/Lists/AccessoryQuestions'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "accessoryquestions-form" */ './Pages/Forms/AccessoryQuestions'),
          ),
        },
        setupDetails: {
          name: 'Setup details',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "setupdetails" */ './Pages/Lists/SetupDetails'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "setupdetails-form" */ './Pages/Forms/SetupDetails'),
          ),
        },
        setupDetailQuestions: {
          name: 'Setup detail questions',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "setupdetailquestions" */ './Pages/Lists/SetupDetailQuestions'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "setupdetailquestions-form" */ './Pages/Forms/SetupDetailQuestions'),
          ),
        },
        trees: {
          name: 'Trees',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "trees" */ './Pages/Lists/Trees')),
          form: React.lazy(() => import(/* webpackChunkName: "trees-form" */ './Pages/Forms/Trees')),
        },
        orders: {
          name: 'Orders',
          roles: ALL_ROLES,
          component: React.lazy(() => import(/* webpackChunkName: "orders" */ './Pages/Lists/Orders')),
          form: React.lazy(() => import(/* webpackChunkName: "orders-form" */ './Pages/Forms/Orders')),
        },
      },
    },
    advertising: {
      name: 'Advertising',
      roles: ALL_ROLES,
      menu: {
        banners: {
          name: 'Banner',
          roles: ALL_ROLES,
          component: React.lazy(() =>
            import(/* webpackChunkName: "advertising" */ './Pages/Lists/Advertisings'),
          ),
          form: React.lazy(() =>
            import(/* webpackChunkName: "advertising-form" */ './Pages/Forms/Advertisings'),
          ),
        },
      },
    },
    permissins: {
      name: 'Permissions',
      roles: ['admin'],
      menu: {
        users: {
          name: 'Users',
          roles: ['admin'],
          component: React.lazy(() => import(/* webpackChunkName: "users" */ './Pages/Lists/Users')),
          form: React.lazy(() => import(/* webpackChunkName: "users-form" */ './Pages/Forms/Users')),
        },
        roles: {
          name: 'Roles',
          roles: ['admin'],
          component: React.lazy(() => import(/* webpackChunkName: "roles" */ './Pages/Lists/Roles')),
          form: React.lazy(() => import(/* webpackChunkName: "roles-form" */ './Pages/Forms/Roles')),
        },
      },
    },
  };

  return navigation;
}

function Application() {
  const [language, setLanguage] = useState('en');
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <AppContext.Provider
      value={{
        user: jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || '') as any, // todo better
        language,
        dir: 'ltr',
        loggedIn,
        getRole: (): string | null => {
          const user = jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || '') as any; // todo better
          return user ? user.role : null;
        },
        getUser: (): any => {
          return jwt.decode(localStorage.getItem(ACCESS_TOKEN_KEY) || ''); // todo better
        },
        getAccessToken: (): string | null | undefined => {
          return localStorage.getItem(ACCESS_TOKEN_KEY);
        },
        setAccessToken: (accessToken: string) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
          if (!accessToken) {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
          }

          setLoggedIn(accessToken ? true : false);
        },
        changeLanguage: language => {
          setLanguage(language);
        },
      }}
    >
      <AppApolloProvider />
    </AppContext.Provider>
  );
}

function AppApolloProvider() {
  const { language, getAccessToken } = useContext(AppContext);
  return (
    <ApolloProvider client={apolloClient(language, getAccessToken())}>
      <ApplicationContentWithRouter />
    </ApolloProvider>
  );
}

function ApplicationContent(props: any) {
  const { user, dir, getAccessToken, setAccessToken, loggedIn, getRole } = useContext(AppContext);

  const accessToken = getAccessToken();
  const { loading: userLoading, data: userData } = useQuery(GET_USER_BY_TOKEN, {
    variables: { accessToken },
  });

  if (userLoading) {
    return <GlobalLoader />;
  }

  // if exists user, update login
  if (userData.users && userData.users[0] && accessToken) {
    setAccessToken(accessToken);
  }

  if (!loggedIn || !user) {
    const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './Pages/Login/index'));

    return (
      <React.Suspense fallback={<ComponentLoader />}>
        <Login setAccessToken={setAccessToken} />
      </React.Suspense>
    );
  }

  const navigation = buildNavigations([], user.id);

  const {
    location: { pathname, search },
  } = props;
  const urlQuery = new URLSearchParams(search);
  const partials = pathname.substr(1).split('/');
  // default section are business cases
  const sectionKey = partials[0] || 'bc';
  const linkKey = partials[1] || '';
  const entityId = partials[2];

  const section = navigation[sectionKey];
  const page = section && section.menu && section.menu[linkKey];
  const conditions = urlQuery.get(`${sectionKey}_conditions`)
    ? JSON.parse(urlQuery.get(`${sectionKey}_conditions`) as any)
    : {};
  const isSidebar = false; /// temporarily disabled for all agendas (page && page.sidebar !== undefined ? page.sidebar : section && section.sidebar) || false;

  const ComponentPageNotFound = React.lazy(() =>
    import(/* webpackChunkName: "ComponentNotFound" */ './Pages/ComponentNotFound'),
  );
  const ComponentToRender = (page && (entityId ? page.form : page.component)) || ComponentPageNotFound;

  const hasRouterPath = !!(page || {}).hasRouterPath;
  const hasAccess = page && ((page.roles && page.roles.includes(getRole())) || !page.roles);
  return (
    <Layout dir={dir}>
      <AppMenu
        sectionKey={sectionKey}
        linkKey={linkKey}
        section={section}
        navigation={navigation}
        createTab={() => {} /*this.createTab*/}
      />
      <Layout>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {navigation[sectionKey] && <Breadcrumb.Item>{navigation[sectionKey].name}</Breadcrumb.Item>}
            {page && <Breadcrumb.Item>{page.name}</Breadcrumb.Item>}
          </Breadcrumb>
          <Content
            style={{
              background: '#fff',
              margin: 0,
              minHeight: 280,
            }}
          >
            <React.Suspense fallback={<ComponentLoader />}>
              {hasAccess && !hasRouterPath && (
                <ComponentToRender
                  createTab={() => {} /*this.createTab*/}
                  entityId={entityId}
                  conditions={conditions}
                  data={entityId === 'new' ? {} : undefined}
                  createFormConditions={page.createFormConditions}
                />
              )}
              {(!hasAccess || hasRouterPath) && <Router />}
            </React.Suspense>
          </Content>
        </Layout>
        {isSidebar && (
          <Sider width={200}>
            <Menu
              selectedKeys={[`${sectionKey}:${linkKey}`]}
              mode='inline'
              style={{ height: '100%', borderRight: 0 }}
            >
              {Object.keys(section.menu).map((nodeKey: any) => {
                const node = section.menu[nodeKey];
                const nodeConditions = section.menu['conditions'];
                return (
                  <Menu.Item key={`${sectionKey}:${nodeKey}`}>
                    <Link
                      to={`/${sectionKey}${(nodeConditions &&
                        `?${sectionKey}_conditions=${nodeConditions}`) ||
                        ''}`}
                    >
                      {node.name}
                    </Link>
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>
        )}
      </Layout>
    </Layout>
  );
}

const ApplicationContentWithRouter = withRouter(ApplicationContent);

export default Application;
